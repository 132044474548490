.modalTitle {
  text-align: center;
  font-size: 18px !important;
  color: #254f38 !important;
  padding: 10px 24px !important;
  margin-bottom: 20px !important;
  background-color: #edf4f0 !important;
}

.modalHeader {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  color: var(--primary-color);
  background: #edf4f0;
  text-align: center;
  padding: 10px;
}

.modalMessage {
  text-align: center;
  max-width: 260px !important;
  margin-bottom: 20px !important;
}

.note {
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.note strong {
  color: var(--primary-color);
}

.submitButton {
  width: 260px !important;
}
