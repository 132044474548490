@keyframes scale-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.checkmarkAnimation {
  animation: scale-up 1s ease-in-out forwards;
}

.fadeInText {
  animation: fade-in 0.75s ease-in-out forwards;
  margin-bottom: 30px !important;
  margin-top: 10px !important;
}
