.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--primary-color);
}
.main h1 span {
  font-size: 65px;
  font-weight: 700;
  text-shadow: 5px 5px #EEE;
}
.main h2 {
  font-size: 16px;
  font-weight: 300;
}
.main h2 a {
  gap: 4px;
  display: flex;
  text-decoration: none;
  justify-content: right;
}
.main p {
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.paper {
  padding: 80px 20px;
}

@media screen and (min-width: 1101px) {
  .header,
  .paper {
    width: 1100px;
  }
}
@media screen and (max-width: 1100px) {
  .header,
  .paper {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .main h2 {
    font-size: 14px;
  }
  .main h2 svg {
    font-size: 20px;
  }
}
