.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main h1 {
  font-size: 20px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 300;
  color: var(--primary-color);
}
.main p {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: rgb(211, 47, 47);
}

.logo {
  margin-bottom: 32px;
}

.form {
  gap: 16px;
  width: 256px;
}

.paper {
  padding: 32px;
}
