.readOnly input {
  cursor: default !important;
}

.modalTitle {
  display: flex !important;
  font-size: 18px !important;
  color: #254f38 !important;
  padding: 10px 24px !important;
  margin-bottom: 20px !important;
  align-items: center !important;
  background-color: #edf4f0 !important;
  justify-content: space-between !important;
}

.messageDetails {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  color: var(--primary-color);
  background: #edf4f0;
  text-align: center;
  padding: 10px;
}

.note {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

@media screen and (max-width: 480px) {
  .cancelButton {
    display: none !important;
  }
}
